export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN = `${BASE_URL}/user/auth/login/`;
export const LOGIN_ACCESS_TOKEN = `${BASE_URL}/user/auth/token/refresh/`;
export const LOG_OUT = `${BASE_URL}/account/logout`;
export const FORGOT_PASSWORD = `${BASE_URL}/user/auth/change_password/`;
export const ApiUrls = {

  //----------------------Dashboard---------------------
  Dashboard:{
    GET_COUNT_LIST: `${BASE_URL}/counters/counters/`,
    GET_WEEKLY_DATA: `${BASE_URL}/counters/week/history/`,
    GET_MONTHLY_DATA: `${BASE_URL}/counters/month/history/`
  },
  //--------------------------------Person-----------------------------
 Person: {
    ADD_PERSON: `${BASE_URL}/persons/add/`,
    ADD_PERSON_IMAGE: `${BASE_URL}/media/upload_image/`,
    GET_PERSON_LIST: `${BASE_URL}/persons/list/`,
    PERSON_DETAIL: `${BASE_URL}/persons/person/`,
    UPDATE_PERSON: `${BASE_URL}/persons/update/`,
    IMPORT_PERSON_BULK: `${BASE_URL}/persons/bulk_add/`,
    PERSON_FILTERS: `${BASE_URL}/persons/test`,
    IMPORT_IMAGES: `${BASE_URL}/media/upload_image/`,
    PERSON_DELETE: `${BASE_URL}/persons/delete/`,
    BULK_ACTIVE: `${BASE_URL}/persons/bulkstatus/active/`,
    BULK_INACTIVE: `${BASE_URL}/persons/bulkstatus/inactive/`,
    BULK_DELETE: `${BASE_URL}/persons/bulkstatus/delete/`,
    ACTIVE_INACTIVE_PERSON: `${BASE_URL}/persons/bulkstatus/`,
  },

    //--------------------------------department and classes-----------------------------

  Lookups_Class: {
    ADD_CLASS: `${BASE_URL}/lookups/class/add/`,
    GET_CLASS_LIST: `${BASE_URL}/lookups/class/list/`,
    CLASS_DETAIL:(id)=> `${BASE_URL}/lookups/class/detail/${id}`,
    UPDATE_CLASS: `${BASE_URL}/lookups/class/update/`,
    CLASS_FILTERS: `${BASE_URL}/persons/test`,
    CLASS_DELETE: `${BASE_URL}/lookups/class/delete/`,
    ACTIVE_INACTIVE_CLASS: `${BASE_URL}/lookups/class/status/update/`,

  },
  Lookups_Department: {
    ADD_DEPARTMENT: `${BASE_URL}/lookups/department/add/`,
    GET_DEPARTMENT_LIST: `${BASE_URL}/lookups/department/list_view/`,
    DEPARTMENT_DETAIL:(id)=> `${BASE_URL}/lookups/department/detail/${id}`,
    UPDATE_DEPARTMENT: `${BASE_URL}/lookups/department/update/`,
    DEPARTMENT_FILTERS: `${BASE_URL}/lookups/department/test`,
    DEPARTMENT_DELETE: `${BASE_URL}/lookups/department/delete/`,
    ACTIVE_INACTIVE_DEPARTMENT: `${BASE_URL}/lookups/department/status/update/`,

  },

  Lookups_Section: {
    ADD_SECTION: `${BASE_URL}/lookups/section/add/`,
    GET_SECTION_LIST: `${BASE_URL}/lookups/section/list/`,
    SECTION_DETAIL:(id)=> `${BASE_URL}/lookups/section/detail/${id}`,
    UPDATE_SECTION: `${BASE_URL}/lookups/section/update/`,
    SECTION_FILTERS: `${BASE_URL}/lookups/persons/test`,
    SECTION_DELETE: `${BASE_URL}/lookups/section/delete/`,
    ACTIVE_INACTIVE_SECTION: `${BASE_URL}/lookups/section/status/update/`,

  },
  Lookups_Branch: {
    ADD_BRANCH: `${BASE_URL}/lookups/branch/add/`,
    GET_BRANCH_LIST: `${BASE_URL}/lookups/branch/list/`,
    BRANCH_DETAIL:(id)=> `${BASE_URL}/lookups/branch/detail/${id}`,
    UPDATE_BRANCH: `${BASE_URL}/lookups/branch/update/`,
    BRANCH_FILTERS: `${BASE_URL}/lookups/branch/test`,
    BRANCH_DELETE: `${BASE_URL}/lookups/branch/delete/`,
    ACTIVE_INACTIVE_BRANCH: `${BASE_URL}/lookups/branch/status/update/`,

  },
  Lookups_Location: {
    ADD_LOCATION: `${BASE_URL}/lookups/location/add/`,
    GET_LOCATION_LIST: `${BASE_URL}/lookups/location/list/`,
    LOCATION_DETAIL: (id)=> `${BASE_URL}/lookups/location/detail/${id}`,
    UPDATE_LOCATION: `${BASE_URL}/lookups/location/update/`,
    LOCATION_FILTERS: `${BASE_URL}/lookups/persons/test`,
    LOCATION_DELETE: `${BASE_URL}/lookups/location/delete/`,
    ACTIVE_INACTIVE_LOCATION: `${BASE_URL}/lookups/location/status/update/`,

  },

  Devices: {
    GET_DEVICE_LIST: `${BASE_URL}/devices/list/`,
    UPDATE_DEVICE: `${BASE_URL}/devices/edit/`,
    UPDATE_PASSWORD: `${BASE_URL}/devices/set-password-request/`,
    DEVICE_DETAIL: (id)=> `${BASE_URL}/devices/${id}`,
    ACTIVE_INACTIVE_DEVICES: `${BASE_URL}/devices/status/update/`,
    DEVICE_SOFT_DELETE: `${BASE_URL}/devices/soft-delete/`,




    // DEVICE_DELETE: `${BASE_URL}/lookups/device/delete/`,
    // ACTIVE_INACTIVE_DEVICE: `${BASE_URL}/lookups/device/is_active/`,

  },
      //-------------------------------- Lookups -----------------------------

  Lookups: {
    GET_ALL_CLASS_LIST: `${BASE_URL}/lookups/class/all_classes/`,
    GET_SECTION: `${BASE_URL}/lookups/get_sections/list/`,
    GET_DEVICE_LOOKUP: (id) => `${BASE_URL}/lookups/devices/list/${id}`,
    GET_SITES_LIST: `${BASE_URL}/lookups/sites/list/`,
    GET_DYNAMIC_TYPES: `${BASE_URL}/lookups/dynamic_types/`,
    GET_LOCATION_LOOKUP_LIST: (id) => `${BASE_URL}/lookups/location/list/${id}`,
    GET_DEPARTMENTS_LIST: `${BASE_URL}/lookups/departments/list/`,
    GET_IMAGE_STATUSES_LIST: `${BASE_URL}/lookups/image_statuses/list/`,
    GET_STATUSES_LIST: `${BASE_URL}/lookups/statuses/list/`,
    GET_ATTENDANCE_TYPE: `${BASE_URL}/lookups/attendance_types/`

  },
  
  Detection: {
    GET_ALL_DETECTIONS: `${BASE_URL}/detections/report_list/`,
    IGNORE_UNIGNORE_DETECTION: `${BASE_URL}/detections/ignore/`,
    GET_ATTENDANCE_BY_ID: (id) => `${BASE_URL}/school_detection/match/${id}`,
    APPROVE_AND_REJECT: (id) => `${BASE_URL}/hitl/action/${id}/`,
  },
  Attendance: {
    GET_ALL_STUDENT_ATTENDANCE: `${BASE_URL}/detections/attendance/`,
    GET_ALL_STAFF_ATTENDANCE: `${BASE_URL}/detections/attendance/`,
    GET_ALL_STAFF_ABSENT: `${BASE_URL}/detections/report_absence`,
    GET_MANUAL_ATTENDANCE_LIST: `${BASE_URL}/persons/persons-search/`,
    ADD_MANUAL_ATTENDANCE: `${BASE_URL}/detections/add/manual/`,
    GET_ATTENDANCE_BY_ID: (id) => `${BASE_URL}/school_detection/attendance/match/${id}`,
    APPROVE_AND_REJECT: (id) => `${BASE_URL}/school_detection/attendance//action/${id}/`,
  },
  

  Media: {
    DELETE_BULK_IMAGE: `${BASE_URL}/media/delete_image/`,
  },
  Users: {
    GET_USERS_LIST: `${BASE_URL}/user/auth/list/`,
    ADD_USER: `${BASE_URL}/user/auth/create/`,
    GET_USER_DETAIL:(id)=> `${BASE_URL}/user/auth/${id}`,
    EDIT_USER: `${BASE_URL}/user/auth/edit/`,
},
};
